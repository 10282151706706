@charset "utf-8";

.logo{
	width: 300px;
	height: 200px;
	background: url(../img/png/logo_ictiva.png) no-repeat;
	display: inline-block;
	vertical-align: top;
	
	img{
		display: none;
	}
}
.registered-top-info{
	display: inline-block;
	width: 50%;
	vertical-align: top;
}
.top-actions{
	display: inline-block;
	width: 20%;
	vertical-align: top;
}